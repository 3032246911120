import React from 'react';
import classNames from 'classnames';
import {useTheme} from "../helpers/hooks/useTheme";

interface LogoProps {
  onClick?: Function,
  className?: string
}

const Logo = (props: LogoProps) => {
  const {onClick, className} = props;
  const { theme, setTheme } = useTheme();

  const handleClick = () => {
    setTheme(undefined);
    onClick?.();
  }

  return <div className={classNames('app-logo', theme, className)} onClick={handleClick} />
};

export default Logo;