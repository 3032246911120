import React from "react";

const AboutPage = (props: any) => {
  // const {orientation} = props;

  return <div className={'page about-page'}>
    <div className={'content'}>
      <p>Cégünk pár éve müködö, folyamatosan fejlödö épületgépészeti ( azon belül víz - gáz és fűtésszerelési ) vállalkozás.</p>
      <p>Alapvetö célunk, olyan gépészeti megoldások megtalálása, mely teljes mértékben megegyezik ügyfeleink igényeivel.</p>
      <p>Versenyképességünk kulcsát kollégáink szakmai felkészültségében, ügyfélcentrikusságában, empátia készségében, megbízhatóságában látjuk.</p>
    </div>
  </div>;
}

export default AboutPage;