import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import classNames from "classnames";

import {Orientation} from "../App";

const BackgroundLoader = (props: any) => {
  const {orientation, theme} = props;

  const [isMounted, setMounted] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getBackgroundImage = useCallback(() => {
    if(orientation === Orientation.landscape) {
      switch (theme) {
        case 'water':
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190674/bg/background-water_b6wydd.jpg';
        case 'heating':
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190671/bg/background-heating_cxtewp.jpg';
        case 'gas':
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190671/bg/background-gas_kythd1.jpg';
        default:
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190671/bg/background-common_p6crv3.jpg';
      }
    } else {
      switch (theme) {
        case 'water':
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190673/bg/background-water-mobile_vxm27s.jpg';
        case 'heating':
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190671/bg/background-heating-mobile_jss9lk.jpg';
        case 'gas':
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190671/bg/background-gas-mobile_a7ncsr.jpg';
        default:
          return 'https://res.cloudinary.com/evosol2020-webpage/image/upload/v1610190671/bg/background-common-mobile_gcywqz.jpg';
      }
    }
  }, [orientation, theme]);

  useLayoutEffect(() => {
    isMounted && setLoaded(false);
  }, [orientation, theme]); // eslint-disable-line react-hooks/exhaustive-deps

  const onImageLoaded = () => {
    setLoaded(true);
  };

  const loaderRef = useRef<any>(null);

  useLayoutEffect(() => {
    const loader = loaderRef.current;
    loader.addEventListener('load', onImageLoaded);

    return () => loader.removeEventListener('load', onImageLoaded);
  }, []);

  return <div className={'background-loader'}>
    {<img src={getBackgroundImage()} alt={''} ref={loaderRef} className={classNames('loader')}/>}
    {!isLoaded && <div className={classNames('background-placeholder', theme)} />}
    {isLoaded && <div className={classNames('background-image', theme)} style={{backgroundImage: `url(${getBackgroundImage()})`}} />}
  </div>
}

export default BackgroundLoader;