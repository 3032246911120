import React, {useCallback, useLayoutEffect, useEffect, useState} from 'react';
import {Route, Switch, Redirect} from "react-router";
import classNames from "classnames";
import {useHistory, useLocation} from "react-router";
import {withOrientationChange, isTablet, isMobileOnly} from "react-device-detect";
import {useWindowSize} from 'react-use';

import SiteHeader from "./components/layout/siteHeader/SiteHeader";
import SiteContent from "./components/layout/SiteContent";
import SiteFooter from "./components/layout/SiteFooter";

import HomePage from "./pages/home";
import ContactPage from "./pages/contact";
import CarrierPage from "./pages/carrier";
import GalleryPage from "./pages/gallery";
import AboutPage from "./pages/about";

import {ThemeContext} from "./helpers/hooks/useTheme";
import SiteMenu from "./components/layout/siteHeader/SiteMenu";

import BackgroundLoader from "./components/BackgroundLoader";

export enum Orientation {
  landscape = 'landscape',
  portrait = 'portrait'
}

const MIN_HEADER_WIDTH = 32 + 212 + 581 + 60 + 20;

let App: any = (props: any) => {
  const { isLandscape, isPortrait } = props;
  const {width} = useWindowSize();

  const history = useHistory();
  const location = useLocation();

  const getThemeFromLocation = (location: any) => {
    if(!location.pathname.includes('topic')) return undefined;
    else {
      return location.pathname.split('/').pop();
    }
  }

  const getOrientation = useCallback(() => {
    if(isPortrait && (isTablet || isMobileOnly)) return Orientation.portrait;
    else if(isLandscape && (isTablet || isMobileOnly)) return Orientation.landscape;
    else return Orientation.landscape;
  }, [isLandscape, isPortrait]);

  const [theme, setTheme] = useState(getThemeFromLocation(location));
  const [orientation, setOrientation] = useState(getOrientation());

  useLayoutEffect(() => {
    history.listen(((newLocation: any) => setTheme(getThemeFromLocation(newLocation))))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isHeaderLinkVisible, setHeaderLinkVisibility] = useState(true);

  useEffect(() => {
    if(width < MIN_HEADER_WIDTH) setHeaderLinkVisibility(false);
    else setHeaderLinkVisibility(true);

  }, [width]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    setOrientation(getOrientation());

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [getOrientation]);

  return <ThemeContext.Provider value={{theme, setTheme}}>
    <div className={classNames('app', theme, orientation)}>
      <BackgroundLoader orientation={orientation} theme={theme}/>
      <SiteHeader siteMenuVisible={isHeaderLinkVisible} />
      {!isHeaderLinkVisible && <SiteMenu />}
      <SiteContent orientation={orientation} >
        <Switch>
          <Route exact path={'/'} render={() => (<HomePage orientation={orientation} />)}/>
          <Route path={'/topic/:topic'} render={() => (<HomePage orientation={orientation} />)}/>
          <Route path={'/contact'} render={() => (<ContactPage orientation={orientation} />)}/>
          <Route path={'/carrier'} render={() => (<CarrierPage orientation={orientation} />)}/>
          <Route path={'/gallery'} render={() => (<GalleryPage orientation={orientation} />)}/>
          <Route path={'/about'} render={() => (<AboutPage orientation={orientation} />)}/>
          <Redirect to={'/'} />
        </Switch>
      </SiteContent>
      <SiteFooter/>
    </div>
  </ThemeContext.Provider>;
}

App = withOrientationChange(App)

export default App;
