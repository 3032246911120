import React, {useEffect, useState} from "react";
import classNames from "classnames";

const GalleryPage = (props: any) => {
  const {orientation} = props;

  const w: any = window;
  const cloudinary: any = w.cloudinary;

  const [isMounted, setMounted] = useState(false);

  const gallery = cloudinary?.galleryWidget({
    container: '#gallery-container',
    cloudName: 'evosol2020-webpage',
    mediaAssets: [{
      tag: 'bath',
      mediaType: 'image',
      transformation: {
        crop: 'pad'
      }
    },
      {
        tag: 'fitting',
        mediaType: 'image',
        transformation: {
          crop: 'pad'
        }
      }],
    displayProps: {
      mode: 'classic'
    },
    thumbnailProps: {
      width: 70,
      height: 70,
      selectedBorderWidth: 2,
      navigationShape: 'round',
      navigationSize: 30,
      navigationColor: 'rgba(255, 255, 255 ,0.7)',
      mediaSymbolShape: 'round',
      mediaSymbolIconColor: 'rgba(0,0,0,0.5)',
    },
    navigation: 'always',
    navigationButtonProps: {
      shape: 'none',
      iconColor: 'rgba(255, 255, 255 ,0.8)',
      size: 40
    },
    carouselStyle: 'thumbnails',
    carouselLocation: 'bottom',
    zoomProps: {
      type: 'popup',
      trigger: 'click'
    },
    aspectRatio: `${orientation === 'portrait' ? '3:4' : '16:9'}`,
    radius: 8,
    carouselOffset: 0,
    indicatorProps: {
      shape: 'round'
    },
    themeProps: {
      active: '#ef9726'
    }
  });

  useEffect(() => {
    gallery.render();
    setMounted(true);

    return () => gallery.destroy();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isMounted && gallery.update();
  }, [orientation]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'gallery-page'}>
    <div id={'gallery-container'} className={classNames('gallery-container', orientation)}/>
  </div>;
}

export default GalleryPage