import React from "react";

const CarrierPage = (props: any) => {
  return <div className={'page carrier-page'}>
    <div className={'content'}>
      <p>Jelenleg nincs nyitott pozíciónk, nézzen vissza később.</p>
    </div>
  </div>;
}

export default CarrierPage;