import React from "react";
import classNames from "classnames";
import {Icon as MdiIcon} from '@mdi/react';
import {mdiFacebook, mdiInstagram, mdiGmail} from "@mdi/js";

import {useTheme} from "../../helpers/hooks/useTheme";

const SiteFooter = () => {
  const {theme} = useTheme();

  return <div className={classNames('site-footer', theme)}>
    <span>EvoSol 2020 - Minden, ami víz és fűtés!</span>
    <span className={'contacts'}>
      <span className={'clickable footer-icon'}
            onClick={() => window.location.replace('https://www.facebook.com/evosol2020/')}>
        <MdiIcon path={mdiFacebook} size={1}/>
      </span>
      <span className={'clickable footer-icon'}
            onClick={() => window.location.replace('https://www.instagram.com/evosol2020/')}>
        <MdiIcon path={mdiInstagram} size={1}/>
      </span>
      <a href={'mailto:evo.sol2020@gmail.com'}>
        <MdiIcon path={mdiGmail} size={1}/>
      </a>
    </span>
  </div>
}

export default SiteFooter;