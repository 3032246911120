import React from 'react';
import classNames from 'classnames';
import {isMobileOnly} from 'react-device-detect';
import {useHistory} from "react-router";

import {renderMenuItems} from "./SiteHeaderCommon";
import Logo from "../../Logo";
import PhoneLink from "../../PhoneLink";
import {useTheme} from "../../../helpers/hooks/useTheme";

interface SiteHeaderProps {
  siteMenuVisible?: Boolean
}

const SiteHeader = (props: SiteHeaderProps) => {
  const {siteMenuVisible} = props;

  const history = useHistory();
  const { theme } = useTheme();

  return <header className={classNames('site-header', theme)}>
    <Logo onClick={() => history.push('/')} className={classNames({'is-mobile': isMobileOnly})} />
    {<div className={classNames('links', {hidden: !siteMenuVisible})}>
      {renderMenuItems()}
    </div>}
    <PhoneLink phoneNumber={'+36-30-179-1474'} mobileView={!siteMenuVisible} />
  </header>;
};

export default SiteHeader;