import React from "react";
import Icon from '@mdi/react';
import classNames from "classnames";

interface TopicCardProps {
  id: string,
  icon: string,
  iconSize?: number,
  title: string,
  onClick: Function,
  orientation: string,
  className?: string,
}

const TopicCard = (props: TopicCardProps) =>{
  const {id, icon, iconSize, title, onClick, orientation, className} = props;

  const defaultIconSize = 2;

  return <div className={classNames('topic', orientation, className)}
              onClick={() => onClick(id)}>
    <Icon className={'topic-icon'} path={icon} size={iconSize || defaultIconSize}/>
    <span>{title}</span>
  </div>;
}

export default TopicCard;