import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {mdiRadiator, mdiFire, mdiShower, mdiWater, mdiThermometerHigh} from "@mdi/js";
import {useHistory, useParams} from "react-router";
import {useTheme} from "../helpers/hooks/useTheme";
import classNames from "classnames";
import {CSSTransition} from "react-transition-group";
import Icon from '@mdi/react';

import TopicCard from "../components/layout/TopicCard";

const HomePage = (props: any) => {
  const {orientation} = props;

  const history = useHistory();
  const params: any = useParams();
  const {theme, setTheme} = useTheme();
  const [selectedTopic, setSelectedTopic] = useState<string>(params.topic);

  useEffect(() => {
    setSelectedTopic(params.topic);
  }, [params.topic]);

  const handleTopicClick = (id: string) => {
    history.push(`/topic/${id}`);
    setTheme(id);
    setSelectedTopic(id);
  };

  const getIconSize = () => window.innerWidth < 500 ? 0.7 : 1;

  const [iconSize, setIconSize] = useState<number>(getIconSize());

  const handleResize = useCallback(() => {
    setIconSize(getIconSize())
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'page home-page'}>
    <CSSTransition in={selectedTopic !== undefined} timeout={0} classNames="content-transition">
      <div className={classNames('content', orientation, theme, {visible: selectedTopic !== undefined})}>
        {selectedTopic !== undefined && <>
          <div className={'title'}>Szolgáltatásaink</div>
          <div className={'services'}>
            {services[selectedTopic].map((item: any) => (
              <div key={item} className={'service'}>
                <Icon className={'service-icon'} path={serviceIcon[selectedTopic]} size={iconSize}/>
                {item}
              </div>
            ))}
          </div>
          <div className={'info'}>A szolgáltatások listája nem teljes, egyéb igény esetén érdeklődjön telefonon, vagy
            közösségi média elérhetőségeinken.
          </div>
        </>}
      </div>
    </CSSTransition>
    <div className={classNames('topic-selector', orientation,
      {active: selectedTopic !== undefined})}>
      <TopicCard id={'water'} icon={mdiShower}
                 title={'Víz'} orientation={orientation}
                 onClick={handleTopicClick}
                 className={classNames({selected: selectedTopic === 'water', sticked: selectedTopic !== undefined})} />
      <TopicCard id={'heating'} icon={mdiRadiator}
                 title={'Fűtés'} orientation={orientation}
                 onClick={handleTopicClick}
                 className={classNames({selected: selectedTopic === 'heating', sticked: selectedTopic !== undefined})} />
      <TopicCard id={'gas'} icon={mdiFire}
                 title={'Gáz'} orientation={orientation}
                 onClick={handleTopicClick}
                 className={classNames({selected: selectedTopic === 'gas', sticked: selectedTopic !== undefined})} />
    </div>
  </div>
}

export default HomePage;

const services: any = {
  water: [
    'Új és meglévő tömbházak vízhálózatának kiépítése',
    'Irodaházak és hotelek vízhálózatának kiépítése',
    'Házi vízmű telepítése és teljeskörű ügyintézés',
    'Komplett vízhálózat tervezés és kivitelezés',
    'Csatornarendszer kiépítés',
    'Szaniter telepítés és csere (WC, mosdókagyló, kád, zuhanykabin, stb.)',
    'Bojler telepítés és csere',
    'Mellékvízórák kialakítása',
    'Strangok cseréje',
    'Kerticsap kiépítés és teljeskörű ügyintézés'
  ],
  heating: [
    'Komplett fűtésrendszer tervezés és kivitelezés',
    'Radiátor telepítés és csere',
    'Csővezték telepítés és csere',
    'Padló- ês fal fűtés tekerés',
    'Mennyezet fűtés-hűtés tekerés',
    'Hőközpont karbantartása és új kialakítása',
    'Fűtés rendszerek átmosása és karbantartása',
    'Fan-coil rendszerek kialakítása és telepítése'
  ],
  gas: [
    'Kazánjavítás és csere',
    'Készülék karbantartás',
    'Gáz hálózat kiépítés',
    'Gázterv teljeskörű ügyintézés',
    'Gáz átadással kapcsolatos teljeskörű ügyintézés',
    'Gázhálózat megszüntetése és alternatív kazán telepítése',
    'Gázkészülékek beüzemelése',
  ]
}

const serviceIcon: any = {
  water: mdiWater,
  heating: mdiThermometerHigh,
  gas: mdiFire
}