import React, {useState, useEffect, useCallback, useLayoutEffect} from 'react';
import { slide as Menu } from 'react-burger-menu';
import {isTablet} from "react-device-detect";
import classNames from "classnames";
import {useParams} from "react-router";
import { Spin as Hamburger } from 'hamburger-react';

import {renderMenuItems} from "./SiteHeaderCommon";
import {useTheme} from "../../../helpers/hooks/useTheme";

const SiteMenu = () => {
  const { theme } = useTheme();
  const params = useParams();
  const [isOpen, setOpened] = useState(false);

  useEffect(() => {
    setOpened(false);
  }, [params]);

  const getIconSize = () => window.innerWidth < 400 ? 28 : 32;

  const [iconSize, setIconSize] = useState<number>(getIconSize());

  const handleResize = useCallback(() => {
    setIconSize(getIconSize())
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <Menu right
               isOpen={isOpen}
               onStateChange={(state: any) => setOpened(state.isOpen)}
               width={isTablet ? '400px' : '100%'}
               burgerButtonClassName={classNames(theme)}
               menuClassName={classNames(theme)}
               customBurgerIcon={<Hamburger toggled={isOpen} toggle={setOpened} size={iconSize} />}
  >
    {renderMenuItems()}
  </Menu>;
};

export default SiteMenu