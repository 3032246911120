import React, {useCallback, useLayoutEffect, useState} from 'react';
import Icon from 'react-icons-kit';
import {phoneCall} from 'react-icons-kit/feather/phoneCall';
import classNames from 'classnames';
import Draggable from 'react-draggable';

interface PhoneLinkProps {
  phoneNumber: string,
  mobileView: boolean,
  className?: string
}

const PhoneLink = (props: PhoneLinkProps) => {
  const {phoneNumber, mobileView, className} = props;

  const [isDragging, setIsDragging] = useState(false);

  const call = () => window.open(`tel:${phoneNumber}`, '_self');

  const isSticky = useCallback(() => {
    // return !mobileView && window.innerWidth < 800;
    return false;
  }, [mobileView]); // eslint-disable-line react-hooks/exhaustive-deps

  const [sticky, setSticky] = useState(isSticky());

  const handleResize = useCallback(() => {
    setSticky(isSticky());
  }, [isSticky]);

  useLayoutEffect(() => {
    if(!mobileView){
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize)
      };
    }
  }, [mobileView]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDragStop = () => {
    if(!isDragging) call();
    setIsDragging(false);
  }

  return <Draggable disabled={!sticky}
                    onDrag={() => setIsDragging(true)}
                    onStop={onDragStop}>
    <div data-testid={'phone-link-component'} onClick={call}
         className={classNames('phone-link-wrapper', className, {mobile: mobileView, sticky: isSticky()})}>
      <div className={'phone-link'}>
        <Icon className={'main-icon'} icon={phoneCall}/>
        {!mobileView && !sticky && <>
          <div className={'title'}><span>Hívjon minket</span></div>
          <div className={'number'}><span>+36-30-179-1474</span></div>
        </>}
      </div>
    </div>
  </Draggable>
};

export default PhoneLink;