import React from "react";

import {GoogleMap, Marker, Polygon, useLoadScript} from '@react-google-maps/api';

const Map = (props: any) => {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: "AIzaSyCg16eOrIMoIMqPM5pdYQzuq_i8v9ZVpaU",
    libraries: ["visualization"],
    language: 'hu'
  })

  if (!isLoaded) return null;

  return <GoogleMap
    mapContainerStyle={containerStyle}
    center={location}
    zoom={10}
  >
    <Marker position={location}/>
    <Polygon
      paths={convertPaths()}
      options={polygonOptions}
    />
  </GoogleMap>;
};

export default Map;

const containerStyle = {
  width: '100%',
  height: '100%'
};

const location = {lat: 47.3177393, lng: 18.9045943};

const polygonPaths = [
  [47.101834, 18.881585],
  [47.133654, 18.740669],
  [47.275063, 18.741497],
  [47.443330, 18.734760],
  [47.528963, 18.817315],
  [47.562965, 19.242325],
  [47.478131, 19.369375],
  [47.373640, 19.367607],
  [47.310191, 19.070550],
  [47.327232, 18.939033]
];

const convertPaths = () => {
  return polygonPaths.map((item: any) => ({ lat: item[0], lng: item[1] }))
};

const polygonOptions = {
  fillColor: "rgba(59, 59, 59, 0.3)",
  fillOpacity: 1,
  strokeColor: "rgba(59, 59, 59, 0.7)",
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: true,
  zIndex: 1
}