import React from 'react';
import {content} from "./siteHeaderContent";
import {NavLink} from 'react-router-dom';
import {isMobileOnly} from 'react-device-detect';
import classNames from 'classnames';

export const renderMenuItems = () => content.map((item) => (
  <NavLink key={item.id} id={item.url} to={item.url} exact={item.url === '/'} className={classNames('nav-item', {'mobile-nav-item' : isMobileOnly})} activeClassName="active-nav-item">
    <span className={'label'}>{item.label}</span>
  </NavLink>
));
