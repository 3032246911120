import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunkMiddleware from "redux-thunk";
import {createBrowserHistory} from 'history';
import {routerMiddleware, connectRouter} from 'connected-react-router';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history)
});

const w: any = window;
const composeEnhancers = process.env.NODE_ENV === 'development' ? w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default function configureStore() {
  const middlewares = [routerMiddleware(history), thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  return createStore(
    rootReducer,
    composeEnhancers(middleWareEnhancer)
  );
}