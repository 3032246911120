import {routes} from "../../../routes";

export const content = [
  {
    id: 'home',
    label: 'Szolgáltatások',
    url: routes.home
  },{
    id: 'contact',
    label: 'Elérhetőségeink',
    url: routes.contact
  },
  {
    id: 'carrier',
    label: 'Karrier',
    url: routes.carrier
  },
  {
    id: 'gallery',
    label: 'Galéria',
    url: routes.gallery
  },
  {
    id: 'about',
    label: 'Rólunk',
    url: routes.about
  },
];
