import React from "react";
import classNames from "classnames";

import Icon from 'react-icons-kit';
import {arrowRight2} from 'react-icons-kit/icomoon/arrowRight2';

import Map from "../components/Map";

const ContactPage = (props: any) => {
  const {orientation} = props;

  return <div className={classNames('page contact-page', orientation)}>
    <div className={'section'}>
      <div className={'title'}>Elérhetőségeink</div>
      <div className={'description'}>
        <p><label>Email:</label><a href={'mailto:evo.sol2020@gmail.com'}>evo.sol2020@gmail.com</a></p>
        <p data-info={'Hívható hétköznapokon 8 - 17 óráig'}><label>Telefon:</label><a href={'tel:+36301791474'}>+36 30 179 1474</a></p>
      </div>
    </div>
    <div className={'section'}>
      <div className={'title'}>Közösségi Média</div>
      <div className={'description'}>
        <p><a href={'https://www.facebook.com/evosol2020/'}>Facebook oldalunk</a><Icon icon={arrowRight2} size={20}/></p>
        <p><a href={'https://www.instagram.com/evosol2020/'}>Instagram oldalunk</a><Icon icon={arrowRight2} size={20}/></p>
      </div>
    </div>
    <div className={'section'}>
      <div className={'title'}>Szolgáltatási terület</div>
      <div className={'map-container'}>
        <Map orientation={orientation}/>
      </div>
    </div>
    <div className={'section'}>
      <div className={'title'}>Cégadatok</div>
      <div className={'description'}>
        <p>Cégnév: EvoSol 2020 Kft.</p>
        <p>Telephely: 2440 Százhalombatta, Arany János utca 1. 4. emelet 14.</p>
        <p>Adószám: 28812003113</p>
        <p>Cégjegyzékszám: 13 09 208410</p>
      </div>
    </div>
  </div>;
}

export default ContactPage;